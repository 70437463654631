export const environment = {

  // this is overwritten in the CI
  apiUrl: 'https://415-covid19-open-data.api.coronavis.dbvis.de/',

  // this is overwritten in the CI
  tileServerUrl: 'https://staging.tiles.coronavis.dbvis.de/',

  version: 'c7ac48b3',

  env: 'review',

  dsn: 'https://6ff2c8f17a534f83bcf18385529d1f59@sentry.io/5185839',

  production: true
};
