import { MatDialog } from '@angular/material/dialog';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import * as L from 'leaflet';
import { CaseDialogComponent } from 'src/app/cases-dod/case-dialog/case-dialog.component';
import { V2DevelopmentProperties } from 'src/app/repositories/types/in/quantitative-rki-case-development';
import { CaseChoroplethColormapService } from 'src/app/services/case-choropleth-colormap.service';
import { CaseUtilService } from 'src/app/services/case-util.service';
import { CaseTooltipComponent } from '../../cases-dod/case-tooltip/case-tooltip.component';
import { TooltipService } from '../../services/tooltip.service';
import { CovidNumberCaseOptions } from '../options/covid-number-case-options';
import { Overlay } from './overlay';

export class CaseChoropleth extends Overlay<V2DevelopmentProperties> {
  constructor(
    name: string,
    hospitals: FeatureCollection<Geometry, V2DevelopmentProperties>,
    private options: CovidNumberCaseOptions,
    private tooltipService: TooltipService,
    private colorsService: CaseChoroplethColormapService,
    private matDialog: MatDialog,
    private caseUtil: CaseUtilService
  ) {
    super(name, hospitals);
  }

  createOverlay() {
    const onAction = (e: L.LeafletMouseEvent,
                      feature: Feature<Geometry, V2DevelopmentProperties>,
                      aggregationLayer1: L.GeoJSON<V2DevelopmentProperties>,
                      layer: L.Layer) => {
      const map = (layer as any)._map;

      const touches = (e.originalEvent as any).touches;

      if ((e.originalEvent as any).triggeredByTouch || touches?.lenght > 1 || !map || map.dragging.moving() || map._animatingZoom) {
        return;
      }

      const onCloseAction: () => void = () => {
        aggregationLayer1.resetStyle(e.target);
      };

      const tooltipComponent = this.tooltipService
        .openAtElementRef(CaseTooltipComponent, {
          x: e.originalEvent.clientX,
          y: e.originalEvent.clientY
        }, onCloseAction);

      tooltipComponent.data = feature.properties;
      tooltipComponent.options = this.options;

      // set highlight style
      const l = e.target;
      l.setStyle({
        weight: 3,
        color: '#666',
        dashArray: '',
        fillOpacity: 0.7
      });

      // l.bringToFront();
    };

    const scaleFn = this.colorsService.getScale(this.featureCollection, this.options);

    // create geojson layer (looks more complex than it is)
    const aggregationLayer = L.geoJSON(this.featureCollection, {
      style: (feature: Feature<Geometry, V2DevelopmentProperties>) => {
        // const numbers = this.colorsService.getCaseNumbers(feature.properties, this.options);

        /**
         * This is the value that will be mapped onto a color.
         */
        const value = this.caseUtil.getCaseNumbers(feature.properties, this.options);

        // reduce fill if not selected
        const fillOp = this.caseUtil.isHoveredOrSelectedBin(this.options, value) ? 1 : 0.65;

        const fillColor = !this.caseUtil.isInFilter(feature, this.options) || isNaN(value) || value === null || value === undefined || !isFinite(value) ? this.colorsService.getUnavailableColor() : this.colorsService.getColorSimple(scaleFn, value, this.options);

        return {
          fillColor,
          weight: 0.5,
          opacity: 1,
          color: 'gray',
          // dashArray: '3',
          fillOpacity: fillOp
        };
      },
      onEachFeature: (feature, layer) => {
        layer.on({
          // on mouseover update tooltip and highlight county
          click: (e) => {
            this.tooltipService.close();
            this.matDialog.open(CaseDialogComponent, {
              maxWidth: '90vw',
              data: {
                data: feature.properties,
                options: this.options
              }
            });
          },
          mouseover: (e: L.LeafletMouseEvent) => onAction(e, feature, aggregationLayer, layer),
          // on mouseover hide tooltip and reset county to normal sytle
          mouseout: (e: L.LeafletMouseEvent) => {
            this.tooltipService.close();
          }
        });
      }
    });

    return aggregationLayer;
  }
}
