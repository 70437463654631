<button *ngIf="!tooltip" mat-raised-button color="primary" i18n="@@caseInfoOpenAsDashboard" (click)="openAsDashboard()">Als Dashboard öffnen</button>

<div class="settings" *ngIf="!tooltip">
  <div class="toggleGroup">
    <h5 i18n="@@infoboxDataSource">Daten Quelle</h5>

    <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style" [value]="chartOptions.dataSource"
      (change)="chartOptions.dataSource = $event.value; updateChartOptions();">
      <mat-button-toggle [value]="'rki'" i18n="@@infoboxDataSourceRKI" i18n-matTooltip="@@tooltipDataSourceRKI"
        matTooltip="Robert Koch Institut">Offizielle Daten
      </mat-button-toggle>
      <mat-button-toggle [value]="'risklayer'" i18n="@@infoboxDataSourceRisklayer"
        i18n-matTooltip="@@tooltipDataSourceRisklayer" matTooltip="Live-Ansicht Risklayer Daten">Live Daten
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="toggleGroup">
    <h5 i18n="@@infoboxType">Art</h5>
    <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style" [value]="chartOptions.type"
      (change)="chartOptions.type = $event.value; updateChartOptions(true)">
      <mat-button-toggle i18n="@@infoboxTypeSick" [value]="eType.cases">Positiv Getestet
      </mat-button-toggle>
      <mat-button-toggle i18n="@@infoboxTypeDeath" [value]="eType.deaths">Todesfälle
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>


  <div class="toggleGroup">
    <h5 i18n="@@infoboxNorm">Normalisierung</h5>

    <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
      [value]="chartOptions.normalization" (change)="chartOptions.normalization = $event.value; updateChartOptions()">
      <mat-button-toggle i18n="@@infoboxNornNone" [value]="eNorm.absolut">Keine
        Normalisierung
      </mat-button-toggle>
      <mat-button-toggle i18n="@@infoboxNornYes" [value]="eNorm.per100k">Je
        100.000 Einwohner
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="toggleGroup">
    <h5 i18n="@@infoboxTime">Zeitraum</h5>

    <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style" [value]="chartOptions.timeWindow"
      (change)="chartOptions.timeWindow = $event.value; updateChartOptions()">
      <mat-button-toggle i18n="@@infoboxTime24" onerror=" " [value]="eTime.twentyFourhours">24 Std.
      </mat-button-toggle>
      <mat-button-toggle i18n="@@infoboxTime72" [value]="eTime.seventyTwoHours">72
        Std.
      </mat-button-toggle>
      <mat-button-toggle i18n="@@infoboxTime7Days" [value]="eTime.sevenDays">7 Tage
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="toggleGroup">
    <h5 i18n="@@caseDodGranularity">Granularität (Zeit)</h5>
    <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style" [value]="chartOptions.timeAgg"
      (change)="chartOptions.timeAgg = $event.value; updateChartOptions()">
      <mat-button-toggle i18n="@@caseDodGranularityDaily" [value]="eGran.yearmonthdate">Täglich</mat-button-toggle>
      <mat-button-toggle i18n="@@caseDodGranularityWeekly" [value]="eGran.yearweek"
        [disabled]="chartOptions.timeWindow !== eTime.sevenDays">Wöchentlich</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="toggleGroup">
    <h5 i18n="@@caseDodAgeBinning">Altersgruppen</h5>
    <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style" [value]="chartOptions.ageGroupBinning"
      (change)="chartOptions.ageGroupBinning = $event.value; updateChartOptions()">
      <mat-button-toggle i18n="@@caseDodAgeGroupBinningAll" [value]="eAgeGroupBinning.all" 
        [disabled]="chartOptions.type !== eType.cases">Altersjahre</mat-button-toggle>
      <mat-button-toggle i18n="@@caseDodAgeGroupBinningFiveYears" [value]="eAgeGroupBinning.fiveyears"
        [disabled]="chartOptions.type !== eType.cases">5 Jahre</mat-button-toggle>
      <mat-button-toggle i18n="@@caseDodAgeGroupBinningRKI" [value]="eAgeGroupBinning.rki">RKI Schema</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="toggleGroup">
    <h5 i18n="@@csaeDodScaling">Skalierung</h5>
    <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style" [value]="chartOptions.scaleType"
      (change)="chartOptions.scaleType = $event.value; updateChartOptions()">
      <mat-button-toggle i18n="@@caseDodScalingLinear" [value]="eScaleType.linear">Linear</mat-button-toggle>
      <mat-button-toggle i18n="@@caseDodScalingSqrt" [value]="eScaleType.sqrt">Quadratwurzel</mat-button-toggle>
      <mat-button-toggle i18n="@@caseDodScalingLog" [value]="eScaleType.symlog">Logarithmisch</mat-button-toggle>
    </mat-button-toggle-group>

    <p class="hint" i18n="@@caseDodScalingHint">Eine nicht-lineare Skalierung hebt niedrige Werte besser hervor.</p>
  </div>
</div>

<div [ngClass]="{'greyblock': !tooltip}">
  <div class="outer">
    <div class="inner">
      <app-table-overview [tableData]="tableData"  [tooltip]="tooltip" [showTitle]="false"></app-table-overview>
    </div>
  </div>
</div>


<h5 class="moreinfo" *ngIf="tooltip" i18n="@@clickForMoreInformation">
  Klicken Sie um mehr Informationen zu erhalten.
</h5>

<div *ngIf="!tooltip" class="greyblock">
  <div class="outer">
    <div class="inner">
      <app-case-line-chart [data]="data" [options]="chartOptions"></app-case-line-chart>
    </div>
  </div>
</div>


<div *ngIf="!tooltip && chartOptions.dataSource !== eDataSource.covid_19_open_data" class="greyblock">
  <div class="outer">
    <div class="inner pixel">
      <app-pixel-chart [dataAndOptions]="pixelChartDataAndOptions"></app-pixel-chart>
    </div>
  </div>
</div>
