import { Injectable } from '@angular/core';
import { AggregationLevel } from '../map/options/aggregation-level.enum';
import { BedType } from '../map/options/bed-type.enum';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private readonly TRANSLATIONS: {translation: string; dataIn?: string; aggLevel?: AggregationLevel; bedType?: BedType; system?: string}[] = [
    {
      dataIn: 'Verfügbar',
      translation: $localize`:@@translationServiceBedsAvailable:Verfügbar`
    },
    {
      dataIn: 'Begrenzt',
      translation: $localize`:@@translationServiceBedsLimited:Begrenzt`
    },
    {
      dataIn: 'Ausgelastet',
      translation: $localize`:@@translationServiceBedsFullyOccupied:Ausgelastet`
    },
    {
      dataIn: 'Nicht verfügbar',
      translation: $localize`:@@translationServiceBedsUnavailable:Nicht verfügbar`
    },
    {
      dataIn: 'Keine Information',
      translation: $localize`:@@translationServiceBedsNoInformation:Keine Information`
    },
    // Abbreviations of capacity stati
    // Verfügbar
    {
      dataIn: 'V',
      translation: $localize`:@@translationServiceBedsShortA:V`
    },
    // Begrenzt
    {
      dataIn: 'B',
      translation: $localize`:@@translationServiceBedsShortL:B`
    },
    // Ausgelastet
    {
      dataIn: 'A',
      translation: $localize`:@@translationServiceBedsShortF:A`
    },
    {
      aggLevel: AggregationLevel.county,
      translation: $localize`:@@translationServiceCounties:Landkreise`
    },
    {
      aggLevel: AggregationLevel.governmentDistrict,
      translation: $localize`:@@translationServiceDistricts:Regierungsbezirke`
    },
    {
      aggLevel: AggregationLevel.state,
      translation: $localize`:@@translationServiceStates:Bundesländer`
    },
    {
      aggLevel: AggregationLevel.country,
      translation: $localize`:@@translationServiceCountries:Länder`
    },
    {
      bedType: BedType.icuLow,
      translation: $localize`:@@translationServiceIcuLow:ICU low`
    },
    {
      bedType: BedType.icuHigh,
      translation: $localize`:@@translationServiceIcuHigh:ICU high`,
    },
    {
      bedType: BedType.ecmo,
      translation: $localize`:@@translationServiceECMO:ECMO`
    }
  ];

  constructor() {}


  public translate(input: string): string {
    for (const t of this.TRANSLATIONS) {

      for (const v of Object.values(t)) {
        if (v === input.trim()) {

          return t.translation;
        }
      }

    }

    console.warn('Could not find a translation for ' + input);
    return input;
  }
}
