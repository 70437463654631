/* eslint-disable @typescript-eslint/quotes, quote-props */

import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CovidNumberCaseChange, CovidNumberCaseNormalization, CovidNumberCaseOptions, CovidNumberCaseTimeWindow, CovidNumberCaseType } from '../map/options/covid-number-case-options';
import { CaseDevelopmentRepository } from '../repositories/case-development.repository';
import { V2TimedStatus } from '../repositories/types/in/quantitative-rki-case-development';
import { Region, V2Region } from '../repositories/types/in/region';
import { PlusminusPipe } from '../shared/plusminus.pipe';
import { getDateTime, getStrDate } from '../util/date-util';
import { CaseUtilService } from './case-util.service';
import { TranslationService } from './translation.service';

export interface TableOverviewData {
  lastUpdated: string;
  dataOutdated: boolean;
  title: string;

  rows: {
    title: string;
    type: CovidNumberCaseType;
    date: string;

    cols: {
      normalization: CovidNumberCaseNormalization;
      timeWindow: CovidNumberCaseTimeWindow;
      change: CovidNumberCaseChange;

      value: string;
      isActive: boolean;
    }[];
  }[];
}

export interface TableOverviewDataAndOptions {
  config: CovidNumberCaseOptions;
  data: TableOverviewData;
}

@Injectable({
  providedIn: 'root'
})
export class TableOverviewService {

  constructor(
    private caseRepo: CaseDevelopmentRepository,
    private caseUtil: CaseUtilService,
    private translation: TranslationService,
    private decimalPipe: DecimalPipe,
    private plusMinusPipe: PlusminusPipe
  ) {}


  compileToDataAndOptions(o: CovidNumberCaseOptions, dataRequests: Region[] | V2Region[]): Observable<TableOverviewDataAndOptions> {
    return this.caseRepo.getCasesDevelopmentAggregated(o.dataSource, dataRequests, false, true)
    .pipe(
      map(d => {
        const data = d.properties;

        const rowsTemplate = [
          {
            type: CovidNumberCaseType.cases,
            title: $localize`:@@tableOverviewRowCases:Positiv Getestet`,
            date: null,
            cols: []
          },
          {
            type: CovidNumberCaseType.deaths,
            title: $localize`:@@tableOverviewRowDeaths:Todesfälle`,
            date: null,
            cols: []
          },
          {
            type: CovidNumberCaseType.tested,
            title: $localize`:@@tableOverviewRowTested:Durchgeführte Tests`,
            date: null,
            cols: []
          },
          {
            type: CovidNumberCaseType.hospitalizedPatients,
            title: $localize`:@@tableOverviewRowHospitalizedPatients:Covid-19 Patient:innen`,
            date: null,
            cols: []
          },
          {
            type: CovidNumberCaseType.icuPatients,
            title: $localize`:@@tableOverviewRowIcuPatients:Covid-19 Intensivpatient:innen`,
            date: null,
            cols: []
          },
          {
            type: CovidNumberCaseType.icuPatientsVentilated,
            title: $localize`:@@tableOverviewRowIcuPatientsVentilated:Covid-19 Intensivpatient:innen (invasiv beatmet)`,
            date: null,
            cols: []
          },
          {
            type: CovidNumberCaseType.bedOccupancyPercent,
            title: $localize`:@@tableOverviewRowbedOccupancyPercent:Bettenauslastung`,
            date: null,
            cols: []
          },
          {
            type: CovidNumberCaseType.personsVaccinated,
            title: $localize`:@@tableOverviewRowbedPersonsVaccinated:Min. Einmal Geimpft`,
            date: null,
            cols: []
          },
          {
            type: CovidNumberCaseType.personsFullyVaccinated,
            title: $localize`:@@tableOverviewRowbedPersonsFullyVaccinated:Vollständig Geimpft`,
            date: null,
            cols: []
          },
          {
            type: CovidNumberCaseType.vaccineDosesAdministered,
            title: $localize`:@@tableOverviewRowbedVaccineDosesAdministered:Verabreichte Impfdosen`,
            date: null,
            cols: []
          },
        ];

        const colTemplate = [
          {
            normalization: CovidNumberCaseNormalization.absolut,
            timeWindow: CovidNumberCaseTimeWindow.all,
            change: CovidNumberCaseChange.absolute,
            statesIdx: 0
          },
          {
            normalization: CovidNumberCaseNormalization.per100k,
            timeWindow: CovidNumberCaseTimeWindow.all,
            change: CovidNumberCaseChange.absolute,
            statesIdx: 0
          }
        ];

        let idx = 1;
        for (const tw of [CovidNumberCaseTimeWindow.twentyFourhours, CovidNumberCaseTimeWindow.seventyTwoHours, CovidNumberCaseTimeWindow.sevenDays]) {
          colTemplate.push({
            normalization: CovidNumberCaseNormalization.absolut,
            timeWindow: tw,
            change: CovidNumberCaseChange.absolute,
            statesIdx: idx
          });

          colTemplate.push({
            normalization: CovidNumberCaseNormalization.per100k,
            timeWindow: tw,
            change: CovidNumberCaseChange.absolute,
            statesIdx: idx
          });

          colTemplate.push({
            normalization: CovidNumberCaseNormalization.absolut,
            timeWindow: tw,
            change: CovidNumberCaseChange.relative,
            statesIdx: idx
          });
          idx++;
        }

        let lastUpdated: string;
        let dataOutdated: boolean;

        // fill values
        for (const row of rowsTemplate) {
          // find the last state in the data
          const refIdx = this.caseUtil.getTimedStatusIdxByDateOrFindLastByValue(d.properties, o.date, row.type);

          // no data available
          if (refIdx === -1) {
            continue;
          }

          const states: V2TimedStatus[] = [];

          const nowState = this.caseUtil.getTimedStatusByIdx(data, refIdx);
          /**
           * The date of the last found timed status
           */
          const mRefDate = getDateTime(nowState.timestamp);

          for (const i of [0, 1, 3, 7]) {
            // states.push(this.caseUtil.getTimedStatusByIdx(data, refIdx - i));
            const oldSlice = this.caseUtil.getTimedStatusByDateOrFindLastByValue(data, getStrDate(mRefDate.minus({days: i})), row.type);
            states.push(oldSlice);
          }

          row.date = nowState.timestamp;

          // this is the most common data type
          if (row.type === CovidNumberCaseType.cases) {
            lastUpdated = nowState.timestamp;
            dataOutdated = !(getDateTime(lastUpdated).hasSame(getDateTime('now'), 'day'));
          }

          const nowVal = this.caseUtil.getTypeAccessorFn(row.type)(nowState);

          for (const col of colTemplate) {
            if (nowVal === null || nowVal === undefined) {
              // row.cols.push({...col, value: '?', isActive: this.isActive(o, row, col)});
              continue;
            }

            const oldState = states[col.statesIdx];

            let oldVal: number;
            if (col.timeWindow !== CovidNumberCaseTimeWindow.all) {
              oldVal = this.caseUtil.getTypeAccessorFn(row.type)(oldState);
            }

            let retVal: string;
            if (col.change === CovidNumberCaseChange.absolute) {
              const diff = nowVal - (oldVal || 0);

              if (col.normalization === CovidNumberCaseNormalization.per100k) {
                retVal = '('+this.getCasesPer100kInhabitants(diff, nowState.population, col.timeWindow !== CovidNumberCaseTimeWindow.all)+'/100k)';
              } else {
                retVal = col.timeWindow === CovidNumberCaseTimeWindow.all ? this.decimalPipe.transform(diff) : this.plusMinusPipe.transform(diff);
              }
            } else {
              // implies relative, also implies timeWindow !== all
              retVal = '('+this.getPercentageChange(nowVal, oldVal)+')';
            }

            if (row.type === CovidNumberCaseType.bedOccupancyPercent) {
              if (nowState.currentICUBedsOccupied === undefined || nowState.currentICUBedsTotal === undefined) {
                continue;
                // retVal = '?';
              } else {
                const propOld = (oldState.currentICUBedsOccupied / oldState.currentICUBedsTotal) * 100;
                const propNew = (nowState.currentICUBedsOccupied / nowState.currentICUBedsTotal) * 100;


                if (col.timeWindow === CovidNumberCaseTimeWindow.all) {
                  retVal = nowState.currentICUBedsOccupied+'/'+nowState.currentICUBedsTotal;

                  if (col.normalization === CovidNumberCaseNormalization.per100k) {
                    retVal = '('+this.decimalPipe.transform(propNew, '1.0-1')+'%)';
                  }
                } else {
                  if (col.change === CovidNumberCaseChange.absolute) {
                    retVal = this.plusMinusPipe.transform(nowState.currentICUBedsOccupied - oldState.currentICUBedsOccupied) + '/' + this.plusMinusPipe.transform(nowState.currentICUBedsTotal - oldState.currentICUBedsTotal);
                  } else {
                    retVal = '('+this.plusMinusPipe.transform(propNew - propOld, '1.0-1')+'%)';
                  }

                  if (col.normalization === CovidNumberCaseNormalization.per100k) {
                    retVal = '';
                  }
                }
              }
            }

            row.cols.push({...col, value: retVal, isActive: this.isActive(o, row, col)});
          }
        }

        const ret: TableOverviewData = {
          lastUpdated,
          dataOutdated,
          title: dataRequests.map(r => (r.description ? r.description + ' ' : '') + r.name).join(', '),
          rows: rowsTemplate
        };

        return {config: o, data: ret};
      })
    );
  }

  private getPercentageChange(curr: number, old: number): string {
    if (curr === old) {
      return '0%';
    }
    const change = ((curr - old) / old) * 100;
    if (change === Infinity) {
      return ('war 0');
    }
    return `${change > 0 ? '+' : ''}${this.decimalPipe.transform(change, '1.0-1')}%`;
  }

  private getCasesPer100kInhabitants(count: number, population: number, addPlus: boolean = false): string {
    const v = ((count / population) * 100000);

    return `${v > 0 && addPlus ? '+' : ''}${this.decimalPipe.transform(v, '1.0-2')}`;
  }

  private isActive(o: CovidNumberCaseOptions, row: {type: CovidNumberCaseType}, col: {
    normalization: CovidNumberCaseNormalization;
    timeWindow: CovidNumberCaseTimeWindow;
    change: CovidNumberCaseChange;
  }): boolean {
    return o.type === row.type && o.change === col.change && o.normalization === col.normalization && o.timeWindow === col.timeWindow;
  }


}
