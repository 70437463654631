<span class="hidden" #i18nAltersgruppe i18n="@@caseDodYAxisAltersgruppe">Altersgruppe</span>
<span class="hidden" #i18nDatum i18n="@@caseDodXAxisDatum">Datum</span>
<span class="hidden" #i18nWoche i18n="@@caseDodXAxisWoche">Woche</span>
<span class="hidden" #i18nVal i18n="@@caseDodZAxisVal">7-Tage-Inzidenz</span>
<span class="hidden" #i18nValDeaths i18n="@@caseDodZAxisValDeaths">7-Tage-Inzidenz (Todesälle)</span>
<span class="hidden" #i18nValAbs i18n="@@caseDodValAbs">Anzahl positiv getestet</span>
<span class="hidden" #i18nValAbsDeaths i18n="@@caseDodValAbsDeaths">Anzahl Todesfälle</span>

<h4 class="title">
    {{ _dataAndOptions?.chartOptions?.title }}
</h4>


<app-vega *ngIf="spec; else loadBlock" [spec]="spec"></app-vega>

<ng-template #loadBlock>
    <div class="loading">
        <mat-spinner diameter="40" strokeWidth="2" style="margin: 40px 80px;"></mat-spinner>
    </div>
</ng-template>


<div class="datasources" *ngIf="_dataAndOptions">
    <p i18n="@@agegroupChartLastWeekHint" class="hint">
        Inzidenzen der aktuellen Kalenderwoche sind niedriger und enthalten Unsicherheiten. Ab Mitte der Woche können Tendenzen sichtbar werden.
    </p>

    <app-data-source [source]="_dataAndOptions.config.dataSource"></app-data-source>
    <app-data-source source="zensus"></app-data-source>
</div>
