import { AggregationLevel } from '../map/options/aggregation-level.enum';
import { CovidNumberCaseDataSource } from '../map/options/covid-number-case-options';

export function aggLevelToEndpointMulti(dataSource: CovidNumberCaseDataSource, aggLevel: AggregationLevel): string {
  switch (dataSource) {
    case CovidNumberCaseDataSource.rki || CovidNumberCaseDataSource.risklayer:
      return aggLevel;

    case CovidNumberCaseDataSource.covid_19_open_data:
      switch(aggLevel) {
        case AggregationLevel.country:
          return '0';

        case AggregationLevel.state:
          return '1';

        case AggregationLevel.county:
          return '2';

        default:
          throw new Error(`Cannot map ${aggLevel} with data source ${dataSource}`);
      }

    default:
      throw new Error(`Cannot map agg level for data source ${dataSource}`);
  }
}

export function aggLevelToEndpointSingle(aggLevel: AggregationLevel): string {
    let aggEndpoint = '';
    switch (aggLevel) {
      case AggregationLevel.county:
        aggEndpoint = 'landkreis';
        break;

      case AggregationLevel.governmentDistrict:
        aggEndpoint = 'regierungsbezirk';
        break;

      case AggregationLevel.state:
        aggEndpoint = 'bundesland';
        break;

      case AggregationLevel.country:
        aggEndpoint = 'land';
        break;

      default:
        throw new Error('Aggregation level ' + aggLevel + ' unknown');
    }
    return aggEndpoint;
}
