import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feature, FeatureCollection, MultiPolygon } from 'geojson';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AggregationLevel } from '../map/options/aggregation-level.enum';
import { CovidNumberCaseDataSource } from '../map/options/covid-number-case-options';
import { getDateTime, getStrDate } from '../util/date-util';
import { CachedRepository } from './cached.repository';
import { AggregatedV2DevelopmentProperties, V2DevelopmentProperties } from './types/in/quantitative-rki-case-development';
import { Region, V2Region } from './types/in/region';
import { RisklayerPrognosis } from './types/in/risklayer-prognosis';
import { aggLevelToInt, prepareAggParams } from './utils';

@Injectable({
  providedIn: 'root'
})
export class CaseDevelopmentRepository {

  constructor(private cachedRepository: CachedRepository) {}

  getCasesDevelopmentForAggLevel(
    dataSource: CovidNumberCaseDataSource,
    aggLevel: AggregationLevel,
    from: string,
    to: string,
    ageGroups: boolean,
    nogeom: boolean
  ): Observable<FeatureCollection<MultiPolygon, V2DevelopmentProperties>> {
    const dataSourceEndpoint = this.getEndpointFromDataSource(dataSource);
    const aggLevelEndpoint = aggLevelToInt(aggLevel);
    return this
      .cachedRepository
      .get<FeatureCollection<MultiPolygon, V2DevelopmentProperties>>(`${environment.apiUrl}v2/${dataSourceEndpoint}/${aggLevelEndpoint}`,
      this.prepareParams(from, to, ageGroups, nogeom));
  }

  getCasesDevelopmentForAggLevelSingle(
    dataSource: CovidNumberCaseDataSource,
    aggLevel: AggregationLevel | number,
    id: string,
    ageGroups: boolean,
    nogeom: boolean,
    to?: string
  ): Observable<Feature<MultiPolygon, V2DevelopmentProperties>> {
    const dataSourceEndpoint = this.getEndpointFromDataSource(dataSource);
    const aggLevelEndpoint = aggLevelToInt(aggLevel);

    return this
      .cachedRepository
      .get<Feature<MultiPolygon, V2DevelopmentProperties>>(`${environment.apiUrl}v2/${dataSourceEndpoint}/${aggLevelEndpoint}/${id}`,
      this.prepareParams(null, to, ageGroups, nogeom));
  }

  getRisklayerPrognosis(): Observable<RisklayerPrognosis> {
    return this
      .cachedRepository
      .get<RisklayerPrognosis>(`${environment.apiUrl}v2/risklayer/prognosis`);
  }

  getCasesDevelopmentAggregated(
    dataSource: CovidNumberCaseDataSource,
    dataRequests: Region[] | V2Region[],
    ageGroups: boolean,
    nogeom: boolean
  ): Observable<Feature<MultiPolygon, AggregatedV2DevelopmentProperties>> {
    const dataSourceEndpoint = this.getEndpointFromDataSource(dataSource);
    return this
      .cachedRepository
      .get<Feature<MultiPolygon, AggregatedV2DevelopmentProperties>>(`${environment.apiUrl}v2/${dataSourceEndpoint}/aggregated`,
      prepareAggParams(dataRequests, ageGroups, nogeom));
  }

  private prepareParams(from?: string, to?: string, ageGroups?: boolean, nogeom?: boolean): HttpParams {
    let params = new HttpParams();

    if (from) {
      const fromDate = getDateTime(from);
      params = params.append('from', getStrDate(fromDate));
    }

    if (to) {
      const toDate = getDateTime(to);
      params = params.append('to', getStrDate(toDate));
    }

    if (ageGroups) {
      params = params.append('agegroups', 'true');
    }

    if (nogeom) {
      params = params.append('nogeom', 'true');
    }

    return params;
  }

  private getEndpointFromDataSource(dataSource: CovidNumberCaseDataSource): string {
    switch(dataSource) {
      case CovidNumberCaseDataSource.rki:
      case CovidNumberCaseDataSource.divi:
        return 'rki';

      case CovidNumberCaseDataSource.risklayer:
        return 'risklayer';

      case CovidNumberCaseDataSource.covid_19_open_data:
        return 'covid-19-open-data';

      case CovidNumberCaseDataSource.survstat:
        return 'survstat';

      default:
        throw new Error(`Unknown data source ${dataSource}`);
    }
  }
}
