import { Component, Input, OnInit } from '@angular/core';
import { CovidNumberCaseDataSource } from 'src/app/map/options/covid-number-case-options';

@Component({
  selector: 'app-data-source',
  templateUrl: './data-source.component.html',
  styleUrls: ['./data-source.component.less']
})
export class DataSourceComponent implements OnInit {

  @Input()
  source: 'rki' | 'risklayer' | 'divi' | 'zensus' | 'survstat' | 'rki_incidences' | CovidNumberCaseDataSource.covid_19_open_data;

  @Input()
  hideHint = false;

  now = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
