import { HttpParams } from '@angular/common/http';
import { isNumber } from 'lodash';
import { AggregationLevel } from '../map/options/aggregation-level.enum';
import { Region, V2Region } from './types/in/region';

export function prepareAggParams(dataRequests: Region[] | V2Region[], ageGroups?: boolean, nogeom?: boolean): HttpParams {
  const map = new Map < number,
    string[] > ();

  dataRequests.forEach((d: Region | V2Region) => {
    const aggLevelInt = aggLevelToInt(d.aggLevel);
    if (!map.has(aggLevelInt)) {
      map.set(aggLevelInt, []);
    }

    map.get(aggLevelInt).push(d.id);
  });

  let params = new HttpParams();

  for (const [key, value] of map) {
    params = params.append('' + key, value.join(','));
  }

  if (ageGroups) {
    params = params.append('agegroups', 'true');
  }

  if (nogeom) {
    params = params.append('nogeom', 'true');
  }

  return params;
}

export function aggLevelToInt(aggLevel: AggregationLevel | number): number {
  if (isNumber(aggLevel)) {
    return aggLevel;
  }

  switch (aggLevel) {
    case AggregationLevel.world:
      return 0;

    case AggregationLevel.continents:
      return 1;

    case AggregationLevel.country:
      return 2;

    case AggregationLevel.state:
      return 3;

    case AggregationLevel.governmentDistrict:
      return 4;

    case AggregationLevel.county:
      return 5;

    default:
      throw new Error(`Unknown aggregation level ${aggLevel}`);
  }
}
