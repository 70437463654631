import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, mergeMap, toArray } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { V2Region } from './types/in/region';

@Injectable({
  providedIn: 'root'
})
export class RegionRepository {

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<V2Region[]> {
    return this.http.get<V2Region[]>(`${environment.apiUrl}v2/regions/germany`);
  }

  getByIds(ids: string[]): Observable<V2Region[]> {
    return this.getAll()
        .pipe(
          mergeMap(d => d),
          filter(reg => ((reg.id.length === 2 && reg.aggLevel === 3) || reg.id.length !==2) && ids.find(r1 => r1 === reg.id) !== undefined),
          toArray()
        );
  }

  v2GetAll(ids?: string[], queryName?: string, agg_level?: number): Observable<V2Region[]> {
    let params = new HttpParams();
    if (ids && ids.length > 0) {
      params = params.set('query', ids.join(','));
    }

    if (queryName) {
      params = params.set('queryName', queryName);
    }

    let extraPath = '';
    if (agg_level !== null && agg_level !== undefined) {
      extraPath = `/${agg_level}`;
    }

    return this.http.get<V2Region[]>(`${environment.apiUrl}v2/regions/global${extraPath}`, {params});
  }
}
