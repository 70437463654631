<span class="hidden-translation" i18n="@@dataWarning" #dataWarning>Aktuelle Inzidenzstatistiken sind durch weniger Meldungen der Gesundheitsämter während der Feiertage zu niedrig und tendenziell wenig aussagekräftig.</span>
<div class="example-container" [class.example-is-mobile]="(isMobile$ | async)">
  <mat-toolbar class="example-toolbar">

    <div class="snav-toggle">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <a mat-button routerLink="/">
      <img class="logo" src="./assets/coronavislogo300.png" alt="CoronaVis logo" />
    </a>

    <div class="snav-desktop">
      

      <a mat-button routerLink="/overview" routerLinkActive="mat-primary" [routerLinkActiveOptions]="{exact: true}"
        i18n="@@overviewIntroduction">Einführung</a>

      <!-- <a mat-button routerLink="/overview/beds" routerLinkActive="mat-primary">Beds</a>

  <a mat-button routerLink="/overview/cases" routerLinkActive="mat-primary">Cases</a> -->

      <a mat-button routerLink="/overview/map/bedcapacities" routerLinkActive="mat-primary"
        i18n="@@overviewICUView">Intensivbetten-Kapazitäten</a>

      <a mat-button routerLink="/overview/map/lockdown" routerLinkActive="mat-primary" [routerLinkActiveOptions]="{exact: true}"
        i18n="@@overviewLockdownView">7-Tage-Inzidenz (RKI)</a>

      <a mat-button routerLink="/overview/map/lockdown-live" routerLinkActive="mat-primary" 
        i18n="@@overviewLockdownViewLive">LIVE 7-Tage-Inzidenz (Risklayer)</a>

      <!-- <a mat-button routerLink="/overview/ebrake" routerLinkActive="mat-primary" 
        i18n="@@overviewEBrakeView">Bundesnotbremse</a> -->

      <a mat-button routerLink="/overview/map/global" routerLinkActive="mat-primary" 
        i18n="@@overviewMapGlobal">Global</a>

      <a mat-button routerLink="/overview/dashboards" routerLinkActive="mat-primary">Dashboards</a>

      <a mat-button routerLink="/overview/subscription" routerLinkActive="mat-primary" 
        i18n="@@overviewSubscription">E-Mail-Benachrichtigung</a>
    </div>


    <span class="spacer"></span>

    <app-language-switcher class="language-switcher"></app-language-switcher>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav #snav mode="over">
      <mat-nav-list class="nav-list">
        <a mat-list-item (click)="snav.close()" routerLink="/overview" routerLinkActive="mat-primary"
          [routerLinkActiveOptions]="{exact: true}" i18n="@@overviewIntroduction">Einführung</a>

        <!-- <a mat-button routerLink="/overview/beds" routerLinkActive="mat-primary">Beds</a>
  
    <a mat-button routerLink="/overview/cases" routerLinkActive="mat-primary">Cases</a> -->

        <a mat-list-item (click)="snav.close()" routerLink="/overview/map/bedcapacities" routerLinkActive="mat-primary"
          i18n="@@overviewICUView">Intensivbetten-Kapazitäten</a>

        <a mat-list-item (click)="snav.close()" routerLink="/overview/map/lockdown" routerLinkActive="mat-primary"
          i18n="@@overviewLockdownView">7-Tage-Inzidenz (RKI)</a>

        <a mat-list-item (click)="snav.close()" routerLink="/overview/map/lockdown-live" routerLinkActive="mat-primary" 
          i18n="@@overviewLockdownViewLive">LIVE 7-Tage-Inzidenz (Risklayer)</a>

        <!-- <a mat-list-item (click)="snav.close()" routerLink="/overview/ebrake" routerLinkActive="mat-primary" 
          i18n="@@overviewEBrakeView">Bundesnotbremse</a> -->

        <a mat-list-item (click)="snav.close()" routerLink="/overview/map/global" routerLinkActive="mat-primary" 
          i18n="@@overviewMapGlobal">Global</a>

        <a mat-list-item (click)="snav.close()" routerLink="/overview/dashboards" routerLinkActive="mat-primary" 
          >Dashboards</a>

        <a mat-list-item (click)="snav.close()" routerLink="/overview/subscription" routerLinkActive="mat-primary" 
          i18n="@@overviewSubscription">E-Mail-Benachrichtigung</a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="overview">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
