<button
  *ngIf="!mo.hideInfobox"
  mat-icon-button
  (click)="mo.extendInfobox = !mo.extendInfobox; emitMapOptions();"
>
  <mat-icon>{{ mo.extendInfobox ? 'layers_clear' : 'layers' }}</mat-icon>
</button>

<app-button-panel 
  *ngIf="mo.hideInfobox"
  [mapOptions]="mo" 
  [mapLocationSettings]="mls"
  mode="vertical"
></app-button-panel>

<mat-card *ngIf="mo.extendInfobox && !mo.hideInfobox">

  <app-hospital-search 
    [data]="searchData$" 
    (selectedHospital)="searchSelected($event)" 
    [reset]="resetHospitalSearch"
  ></app-hospital-search>

  <div class="accordion-container">
  <mat-accordion class="accordion">

    <mat-expansion-panel *ngIf="mo.covidNumberCaseOptions.dataSource !== eCovidNumberCaseDataSource.covid_19_open_data" [expanded]="mo.bedGlyphOptions.enabled || mo.bedBackgroundOptions.enabled">
      <mat-expansion-panel-header>
        <mat-panel-title class="flexPanelTitle">
            <mat-icon>{{ mo.bedGlyphOptions.enabled || mo.bedBackgroundOptions.enabled ? 'toggle_on' : 'toggle_off' }}</mat-icon> 
            <span i18n="@@infoboxHospitalBeds">Krankenhäuser - Bettenauslastung</span>
          </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-progress-bar *ngIf="!combinedStats$" mode="indeterminate"></mat-progress-bar>

      <div *ngIf="combinedStats$ | async as c">
        <div *ngIf="c.diviFiltered !== undefined && c.diviUnfiltered !== undefined; else noDiviData">
          <p class="stats with-addon" i18n="@@infoboxNumberOfHospitals">{{ c.diviUnfiltered.num_hospitals | number }} bundesweit gemeldete Einrichtungen</p>
          <p class="stats"><small i18n="@@infoboxNumberOfHospitalsAddon">(davon {{ (c.diviUnfiltered.num_hospitals - c.diviFiltered.num_hospitals) | number }} ausgeblendet, da die letzte Datenaktualisierung älter als 5 Tage ist)</small></p>
          <p class="stats" i18n="@@infoboxLastUpdate">Letzter Stand: {{ c.diviFiltered.last_updated | date:'full' }}</p>
        </div>
        <ng-template #noDiviData>
          <div class="stats" i18n="@@infoboxNoDiviData">Es stehen momentan keine Bettenkapazitätsdaten<br>für diesen Zeitpunkt zur Verfügung.</div>
        </ng-template>
      </div>
      
      

      <h5>Aggregation</h5>

      <mat-button-toggle-group
        class="btngrp"
        name="fontStyle"
        aria-label="Font Style"
        [value]="mo.bedGlyphOptions.aggregationLevel"
        (change)="updateBedGlyphAggregationLevel($event.value)"
      >
        <mat-button-toggle [value]="eAggregationLevels.country" i18n="@@infoboxCountries" i18n-matTooltip="@@CountryTP"
                           matTooltip="Deutschland">Deutschland
        </mat-button-toggle>
        <mat-button-toggle [value]="eAggregationLevels.state" i18n="@@infoboxStates" i18n-matTooltip="@@StatesTP"
                           matTooltip="Bundesländer">Länder
        </mat-button-toggle>
        <mat-button-toggle [value]="eAggregationLevels.governmentDistrict" i18n="@@infoboxRegions"
                           i18n-matTooltip="@@RegionsTP" matTooltip="Regierungsbezirke">Bezirke
        </mat-button-toggle>
        <mat-button-toggle [value]="eAggregationLevels.county" i18n="@@infoboxCounties" i18n-matTooltip="@@CountiesTP"
                           matTooltip="Landkreise">Kreise
        </mat-button-toggle>
        <mat-button-toggle [value]="eAggregationLevels.none" i18n="@@infoboxNone" i18n-matTooltip="@@NoneTP"
                           matTooltip="Ohne Aggregation">Keine
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-divider></mat-divider>
      <br/>

      <h5>Glyph</h5>
      <mat-progress-bar *ngIf="glyphLoading" mode="indeterminate"></mat-progress-bar>
      <p>
        <mat-slide-toggle
          [checked]="mo.bedGlyphOptions.enabled"
          (change)="mo.bedGlyphOptions.enabled = $event.checked; emitMapOptions()"
          i18n="@@infoboxBelegungShow"
        >Bettenauslastung anzeigen / ausblenden
        </mat-slide-toggle>
      </p>

      <!--<mat-divider></mat-divider>-->

      <div *ngIf="mo.bedGlyphOptions.enabled">
        <div class="glyph-legend" *ngIf="combinedStats$ | async; let c">
          <div
            *ngFor="let t of c.glyphData"
            class="glyph-tile"
            (mouseenter)="openBedTooltip($event, t)"
            (mouseleave)="tooltipService.close()"
            (click)="mo.bedGlyphOptions[t.accessor] = !mo.bedGlyphOptions[t.accessor]; emitMapOptions()"
          >
            <div class="rect"
                 [ngStyle]="{'background-color': t.color, 'border-color': mo.bedGlyphOptions[t.accessor] ? 'grey' : 'lightgrey'}"></div>
            <div class="text">{{ t.name }}</div>
          </div>
        </div>
        <mat-hint class="color-expl" i18n="@@infoboxColorExpl">* Die Farben stellen den bundesweiten Durchschnitt dar.</mat-hint>
        
        <app-bed-inline-legend></app-bed-inline-legend>

        <mat-divider></mat-divider>
        <br/>

        <h5 i18n="@@infoboxPositionign">Positionierung</h5>
        <mat-button-toggle-group
          class="btngrp"
          name="fontStyle"
          aria-label="Font Style"
          [value]="mo.bedGlyphOptions.forceDirectedOn"
          (change)="mo.bedGlyphOptions.forceDirectedOn = $event.value; emitMapOptions()"
        >
          <mat-button-toggle [value]="true" i18n="@@infoboxForceDirectedOnTP" i18n-matTooltip="@@ForceDirectedOnTP"
                             matTooltip="Position der Krankenhäuser entzerren">Verdeckungsfrei
          </mat-button-toggle>
          <mat-button-toggle [value]="false" i18n="@@infoboxForceDirectedOffTP" i18n-matTooltip="@@ForceDirectedOffTP"
                             matTooltip="Position der Krankenhäuser beibehalten">Exakte Position
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <h5 i18n="@@infoboxBackground">Hintergrund</h5>
      <mat-progress-bar *ngIf="bedChoroplethLoading" mode="indeterminate"></mat-progress-bar>

      <mat-slide-toggle
        [disabled]="mo.bedGlyphOptions.aggregationLevel === eAggregationLevels.none"
        [checked]="mo.bedBackgroundOptions.enabled"
        (change)="updateBedBackgroundOptionsEnabled($event.checked)"
        i18n="@@infoboxBackgroundShow"
      >Bettenauslastung im Hintergrund anzeigen / ausblenden
      </mat-slide-toggle>
      <div *ngIf="mo.bedGlyphOptions.aggregationLevel === eAggregationLevels.none">
        <mat-error i18n="@@infoboxBackgroundError">Bitte eine Aggregation Länder, Bezirke, oder Kreise auswählen.
        </mat-error>
      </div>


      <mat-button-toggle-group
        *ngIf="mo.bedBackgroundOptions.enabled"
        class="btngrp"
        name="fontStyle"
        aria-label="Font Style"
        [value]="mo.bedBackgroundOptions.bedType"
        (change)="updateBedBackgroundBedType($event.value)"
        [disabled]="mo.bedGlyphOptions.aggregationLevel === eAggregationLevels.none"
      >
        <mat-button-toggle [value]="eBedTypes.icuLow">ICU low</mat-button-toggle>
        <mat-button-toggle [value]="eBedTypes.icuHigh">ICU high</mat-button-toggle>
        <mat-button-toggle [value]="eBedTypes.ecmo">ECMO</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="mo.covidNumberCaseOptions.dataSource !== eCovidNumberCaseDataSource.covid_19_open_data" [expanded]="mo.covidNumberCaseOptions.enabled">
      <mat-expansion-panel-header>
        <mat-panel-title class="flexPanelTitle">
          <mat-icon>{{ mo.covidNumberCaseOptions.enabled ? 'toggle_on' : 'toggle_off' }}</mat-icon> 
          <span i18n="@@infoboxCaseView">Fallansicht</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngIf="(combinedStats$ | async); let c">
        <div *ngIf="c.rki?.cumulativeConfirmed else noRKIData">
          <p class="stats" i18n="@@infoboxNumberOfCovidCases">{{ c.rki?.cumulativeConfirmed | number }} bundesweit gemeldete Covid-19 Fälle</p>

          <p class="stats" *ngIf="mo.covidNumberCaseOptions.dataSource === 'risklayer'" i18n="@@infoboxPrognosis">
            Hochrechnung: {{ c.risklayerPrognosis | number }} neue Covid-19 Fälle heute
            <mat-icon
              style="vertical-align: middle;"
              i18n-matTooltip="@@tooltipRisklayerPrognosis"
              matTooltip="Diese Hochrechnung basiert auf den bereits eingegangen sowie vorläufigen Meldungen der Landkreise bzw. Gesundheitsämter. Je höher die Zahl der Landkreise mit verfügbaren Daten, desto höher ist die Genauigkeit der Hochrechnung."
            >help</mat-icon>
          </p>

          <p class="stats" i18n="@@infoboxNumberOfCovidDeaths">{{ c.rki?.cumulativeDeceased | number }} bundesweit gemeldete Covid-19 Todesfälle</p>

          <p class="stats" *ngIf="mo.covidNumberCaseOptions.dataSource === 'risklayer' && mo.covidNumberCaseOptions.aggregationLevel === eAggregationLevels.county">
            <span i18n="@@infoboxCountiesAvailable">Daten von {{ c.casesCountiesAvailable }}/{{ c.casesCountiesTotal }} Landkreisen verfügbar </span>
            <mat-icon
              style="vertical-align: middle;"
              i18n-matTooltip="@@tooltipNumberOfCounties"
              matTooltip="Die Daten werden von Freiwilligen in einer öffentlich zugänglichen Tabelle gesammelt (crowdsourcing) sobald die Landkreise bzw. Gesundheitsämter die Daten veröffentlichen. Falls noch keine aktuellen Daten vorliegen werden die Daten vom vorherigen Tag verwendet."
            >help</mat-icon>
            <mat-checkbox [(ngModel)]="mo.covidNumberCaseOptions.showOnlyAvailableCounties" (ngModelChange)="emitMapOptions()" i18n="@@infoboxShowOnlyAvailableCounties">
              nur Landkreise mit aktuellen Daten anzeigen
            </mat-checkbox>
          </p>

          <p class="stats">
            <span i18n="@@infoboxLastUpdate">Letzter Stand: {{ c.rki?.timestamp | date:'full' }}</span>
          </p>

          <p class="stats flex" *ngIf="mo.covidNumberCaseOptions.dataSource === 'risklayer'">
            <span i18n="@@infoboxNextUpdate">Nächste Aktualisierung: </span>
            <mat-spinner diameter="20" strokeWidth="3" mode="determinate" [value]="nextLiveUpdatePercentage" class="small"></mat-spinner>
            {{ nextLiveUpdate }}
          </p>

          <div class="data-warning" *ngIf="c.rkiOutdated">
            <mat-icon color="warn">warning</mat-icon>
    
            <h5 i18n="@@tooltipHospitalOldData">Die Daten sind älter als 24 Stunden.</h5>
          </div>
        </div>
        <ng-template #noRKIData>
          <div class="stats" i18n="@@infoboxNoRKIData">Es stehen momentan keine Inzidenzstatistiken<br>für diesen Zeitpunkt zur Verfügung.</div>
        </ng-template>
      </div>
      

      <form class="covid-form">
        <mat-slide-toggle
          [checked]="mo.covidNumberCaseOptions.enabled"
          (change)="updateCovidNumberCaseOptionsEnabled($event.checked)"
          i18n="@@infoboxCaseViewShow"
        >Covid-19 Fallansicht anzeigen / ausblenden
        </mat-slide-toggle>

        <div *ngIf="mo.covidNumberCaseOptions && mo.covidNumberCaseOptions.enabled">
          <mat-progress-bar *ngIf="caseChoroplethLoading" mode="indeterminate"></mat-progress-bar>

          <div class="toggleGroup">
            <h5 i18n="@@infoboxDataSource">Daten Quelle</h5>
    
            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.dataSource"
              (change)="mo.covidNumberCaseOptions.dataSource = $event.value; emitMapOptions(); updateStatistics()"
            >
              <mat-button-toggle [value]="'rki'" i18n="@@infoboxDataSourceRKI" i18n-matTooltip="@@tooltipDataSourceRKI"
                                 matTooltip="Robert Koch Institut">Offizielle Daten
              </mat-button-toggle>
              <mat-button-toggle [value]="'risklayer'" i18n="@@infoboxDataSourceRisklayer" i18n-matTooltip="@@tooltipDataSourceRisklayer"
                                 matTooltip="Live-Ansicht Risklayer Daten">Live Daten
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="toggleGroup">
            <h5 i18n="@@infoboxAggregation">Aggregation</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.aggregationLevel"
              (change)="mo.covidNumberCaseOptions.aggregationLevel = $event.value; emitMapOptions()"
            >
              <mat-button-toggle [value]="eAggregationLevels.country" i18n="@@infoboxCountries" i18n-matTooltip="@@CountryTP"
                                 matTooltip="Deutschland">Deutschland
              </mat-button-toggle>
              <mat-button-toggle [value]="eAggregationLevels.state" i18n="@@infoboxStates" i18n-matTooltip="@@StatesTP"
                                 matTooltip="Bundesländer">Länder
              </mat-button-toggle>
              <mat-button-toggle [value]="eAggregationLevels.governmentDistrict" i18n="@@infoboxRegions"
                                 i18n-matTooltip="@@RegionsTP" matTooltip="Regierungsbezirke">Bezirke
              </mat-button-toggle>
              <mat-button-toggle [value]="eAggregationLevels.county" i18n="@@infoboxCounties"
                                 i18n-matTooltip="@@CountiesTP" matTooltip="Landkreise">Kreise
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          

          <!--<mat-divider></mat-divider>-->

          <div class="toggleGroup">
            <h5 i18n="@@infoboxTime">Zeitraum</h5>
            <mat-button-toggle-group
              *ngIf="mo.covidNumberCaseOptions && mo.covidNumberCaseOptions.enabled"
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.timeWindow"
              (change)="mo.covidNumberCaseOptions.timeWindow = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxTimeAll" [value]="eCovidNumberCaseTimeWindow.all"
                                 [disabled]="mo.covidNumberCaseOptions.change === eCovidNumberCaseChange.relative">Alle
                Daten
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTime24" onerror=" "
                                 [value]="eCovidNumberCaseTimeWindow.twentyFourhours">24 Std.
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTime72" [value]="eCovidNumberCaseTimeWindow.seventyTwoHours">72
                Std.
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTime7Days" [value]="eCovidNumberCaseTimeWindow.sevenDays">7 Tage
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <!--<mat-divider></mat-divider>-->

          <!-- <div class="toggleGroup">
            <h5 i18n="@@infoboxCaseNum">Fallzahlen</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.change"
              (change)="mo.covidNumberCaseOptions.change = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxCasesAll" [value]="eCovidNumberCaseChange.absolute">Alle erfassten
                Fälle
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxCasesDiff" [value]="eCovidNumberCaseChange.relative"
                                 [disabled]="eCovidNumberCaseTimeWindow.all === mo.covidNumberCaseOptions.timeWindow || eCovidNumberCaseNormalization.per100k === mo.covidNumberCaseOptions.normalization">
                Veränderung im gewählten Zeitraum
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div> -->

          <!--<mat-divider></mat-divider>-->

          <div class="toggleGroup">
            <h5 i18n="@@infoboxType">Art</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.type"
              (change)="mo.covidNumberCaseOptions.type = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxTypeSick" [value]="eCovidNumberCaseType.cases">Positiv Getestet
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTypeDeath" [value]="eCovidNumberCaseType.deaths">Todesfälle
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <!--<mat-divider></mat-divider>-->

          <div class="toggleGroup">
            <h5 i18n="@@infoboxNorm">Normalisierung</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.normalization"
              (change)="mo.covidNumberCaseOptions.normalization = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxNornNone" [value]="eCovidNumberCaseNormalization.absolut">Keine
                Normalisierung
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxNornYes" [value]="eCovidNumberCaseNormalization.per100k"
                                 [disabled]="mo.covidNumberCaseOptions.change === eCovidNumberCaseChange.relative">Je
                100.000 Einwohner
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="toggleGroup" *ngIf="isEBreakModePossible()">
            <h5 i18n="@@infoBoxEBrake">4. Bevölkerungsschutzgesetz "Bundesnotbremse"</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.eBrakeOver"
              (change)="mo.covidNumberCaseOptions.eBrakeOver= $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxEBrakeOff" [value]="undefined">Aus</mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxEBrakeOver100" [value]="100"
                                 >Über 100
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxEBrakeOver165" [value]="165"
                                 >Über 165
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="sliderContainer" *ngIf="isLockDownMode()">
            <h5>Trend Filter</h5>
            <nouislider 
              *ngIf="noUiSliderConfigTrend && mo.covidNumberCaseOptions.trendRange"
              class="trendslider" 
              [config]="noUiSliderConfigTrend"
              [(ngModel)]="mo.covidNumberCaseOptions.trendRange"
              [ngModelOptions]="{standalone: true}"
              (change)="trendSliderChanged($event)"
              ></nouislider>

              <p class="hint" i18n="@@infoBoxTrendFilterHint">Ein positiver Wertebereich filtert die Regionen in denen die Inzidenz steigt.</p>
          </div>
        </div>

      </form>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="mo.covidNumberCaseOptions.dataSource === eCovidNumberCaseDataSource.covid_19_open_data" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="flexPanelTitle">
          <mat-icon>{{ mo.covidNumberCaseOptions.enabled ? 'toggle_on' : 'toggle_off' }}</mat-icon> 
          <span i18n="@@infoboxGlobalOptions">Ansichtsoptionen</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

   

      <form class="covid-form">
        <div *ngIf="mo.covidNumberCaseOptions && mo.covidNumberCaseOptions.enabled">
          <mat-progress-bar *ngIf="caseChoroplethLoading" mode="indeterminate"></mat-progress-bar>

          <div class="toggleGroup">
            <h5 i18n="@@infoboxAggregation">Aggregation</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.aggregationLevel"
              (change)="mo.covidNumberCaseOptions.aggregationLevel = $event.value; emitMapOptions()"
            >
              <!-- <mat-button-toggle [value]="eAggregationLevels.world" i18n="@@infoboxGlobalWorld" i18n-matTooltip="@@WorldGlobalTP"
                                 matTooltip="Welt">Welt
              </mat-button-toggle>
            <mat-button-toggle [value]="eAggregationLevels.continents" i18n="@@infoboxGlobalContinents" i18n-matTooltip="@@ContinentsGlobalTP"
                                 matTooltip="Kontinente">Kontinente
              </mat-button-toggle> -->
              <mat-button-toggle [value]="eAggregationLevels.country" i18n="@@infoboxGlobalCountries" i18n-matTooltip="@@CountryGlobalTP"
                                 matTooltip="Länder oder equivalent">Länder
              </mat-button-toggle>
              <mat-button-toggle [value]="eAggregationLevels.state" i18n="@@infoboxGlobalStates" i18n-matTooltip="@@StatesGlobalTP"
                                 matTooltip="Bundesländer oder equivalent">Bundesländer
              </mat-button-toggle>
              <mat-button-toggle [value]="eAggregationLevels.county" i18n="@@infoboxGlobalCounties"
                                 i18n-matTooltip="@@CountiesGlobalTP" matTooltip="coming soon!" [disabled]="env.production">Kreise
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          

          <!--<mat-divider></mat-divider>-->

          <div class="toggleGroup">
            <h5 i18n="@@infoboxTime">Zeitraum</h5>
            <mat-button-toggle-group
              *ngIf="mo.covidNumberCaseOptions && mo.covidNumberCaseOptions.enabled"
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.timeWindow"
              (change)="mo.covidNumberCaseOptions.timeWindow = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxGlobalTimeAll" [value]="eCovidNumberCaseTimeWindow.all"
                                 [disabled]="mo.covidNumberCaseOptions.change === eCovidNumberCaseChange.relative">Aktuell (akkumuliert)
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTime24" onerror=" "
                                 [value]="eCovidNumberCaseTimeWindow.twentyFourhours">24 Std.
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTime72" [value]="eCovidNumberCaseTimeWindow.seventyTwoHours">72
                Std.
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTime7Days" [value]="eCovidNumberCaseTimeWindow.sevenDays">7 Tage
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <!--<mat-divider></mat-divider>-->

          <!-- <div class="toggleGroup">
            <h5 i18n="@@infoboxCaseNum">Fallzahlen</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.change"
              (change)="mo.covidNumberCaseOptions.change = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxCasesAll" [value]="eCovidNumberCaseChange.absolute">Alle erfassten
                Fälle
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxCasesDiff" [value]="eCovidNumberCaseChange.relative"
                                 [disabled]="eCovidNumberCaseTimeWindow.all === mo.covidNumberCaseOptions.timeWindow || eCovidNumberCaseNormalization.per100k === mo.covidNumberCaseOptions.normalization">
                Veränderung im gewählten Zeitraum
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div> -->

          <!--<mat-divider></mat-divider>-->

          <div class="toggleGroup">
            <h5 i18n="@@infoboxGlobalTypeEpidemiological">Daten: Epidemiologisch</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.type"
              (change)="mo.covidNumberCaseOptions.type = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxTypeSick" [value]="eCovidNumberCaseType.cases">Positiv Getestet
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTypeDeath" [value]="eCovidNumberCaseType.deaths">Todesfälle
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTypeRecovered" [value]="eCovidNumberCaseType.recovered">Gesundet
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTypeTested" [value]="eCovidNumberCaseType.tested">Getestet
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>


          <div class="toggleGroup">
            <h5 i18n="@@infoboxGlobalTypeHospitalizations">Daten: Krankenhausaufenthalte</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.type"
              (change)="mo.covidNumberCaseOptions.type = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxTypeHospitalizedPatients" [value]="eCovidNumberCaseType.hospitalizedPatients">Patienten
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTypeIcuPatients" [value]="eCovidNumberCaseType.icuPatients">Intensivpatienten
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTypeIcuPatientsVentilated" [value]="eCovidNumberCaseType.icuPatientsVentilated">Beatmete Intensivpatienten
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="toggleGroup">
            <h5 i18n="@@infoboxGlobalTypeVaccinations">Daten: Impfungen</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.type"
              (change)="mo.covidNumberCaseOptions.type = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxTypePersonsVaccinated" [value]="eCovidNumberCaseType.personsVaccinated">Min. erste Impfung
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTypePersonsFullyVaccinated" [value]="eCovidNumberCaseType.personsFullyVaccinated">Vollständig Geimpft
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxTypeVaccineDosesAdministered" [value]="eCovidNumberCaseType.vaccineDosesAdministered">Verabreichte Impfdosen
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <!--<mat-divider></mat-divider>-->

          <div class="toggleGroup">
            <h5 i18n="@@infoboxNorm">Normalisierung</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.normalization"
              (change)="mo.covidNumberCaseOptions.normalization = $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxNornNone" [value]="eCovidNumberCaseNormalization.absolut">Keine
                Normalisierung
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxNornYes" [value]="eCovidNumberCaseNormalization.per100k"
                                 [disabled]="mo.covidNumberCaseOptions.change === eCovidNumberCaseChange.relative">Je
                100.000 Einwohner
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="toggleGroup" *ngIf="isEBreakModePossible()">
            <h5 i18n="@@infoBoxEBrake">4. Bevölkerungsschutzgesetz "Bundesnotbremse"</h5>

            <mat-button-toggle-group
              class="btngrp"
              name="fontStyle"
              aria-label="Font Style"
              [value]="mo.covidNumberCaseOptions.eBrakeOver"
              (change)="mo.covidNumberCaseOptions.eBrakeOver= $event.value; emitMapOptions()"
            >
              <mat-button-toggle i18n="@@infoboxEBrakeOff" [value]="undefined">Aus</mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxEBrakeOver100" [value]="100"
                                 >Über 100
              </mat-button-toggle>
              <mat-button-toggle i18n="@@infoboxEBrakeOver165" [value]="165"
                                 >Über 165
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="sliderContainer" *ngIf="isLockDownMode()">
            <h5>Trend Filter</h5>
            <nouislider 
              *ngIf="noUiSliderConfigTrend && mo.covidNumberCaseOptions.trendRange"
              class="trendslider" 
              [config]="noUiSliderConfigTrend"
              [(ngModel)]="mo.covidNumberCaseOptions.trendRange"
              [ngModelOptions]="{standalone: true}"
              (change)="trendSliderChanged($event)"
              ></nouislider>

              <p class="hint" i18n="@@infoBoxTrendFilterHint">Ein positiver Wertebereich filtert die Regionen in denen die Inzidenz steigt.</p>
          </div>
        </div>

      </form>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="mo.covidNumberCaseOptions.dataSource !== eCovidNumberCaseDataSource.covid_19_open_data" [expanded]="mo.showOsmHospitals || mo.showOsmHeliports">
      <mat-expansion-panel-header>
        <mat-panel-title class="flexPanelTitle">
          <mat-icon>{{ mo.showOsmHeliports || mo.showOsmHospitals ? 'toggle_on' : 'toggle_off' }}</mat-icon> 
          <span i18n="@@infoboxGeo">Geographische Verortung</span>
        </mat-panel-title>
        <mat-panel-description>
          <!-- description -->
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-progress-bar *ngIf="osmLoading" mode="indeterminate"></mat-progress-bar>

      <div>
        <mat-slide-toggle
          [checked]="mo.showOsmHospitals"
          (change)="mo.showOsmHospitals = !mo.showOsmHospitals; emitMapOptions()"
          i18n="@@infoboxGeoHospital"
        >Krankenhäuser
        </mat-slide-toggle>
      </div>
      <div>
        <mat-slide-toggle
          [checked]="mo.showOsmHeliports"
          (change)="mo.showOsmHeliports = !mo.showOsmHeliports; emitMapOptions()"
          i18n="@@infoboxGeoHeli"
        >Hubschrauberlandeplätze
        </mat-slide-toggle>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title i18n="@@infoboxData">
          Datenquellen
        </mat-panel-title>
      </mat-expansion-panel-header>


      <mat-list role="list">
        <mat-list-item role="listitem">
          <a i18n="@@infoboxDIVI" href="https://www.intensivregister.de/#/intensivregister" target="_blank">DIVI
            Intensivregister</a>
        </mat-list-item>

        <mat-list-item>
          <a i18n="@@infoboxRKI" href="https://rki.de" target="_blank">Robert Koch Institut</a>
        </mat-list-item>

        <mat-list-item role="listitem">
          <a i18n="@@infoboxRKICases" href="https://corona.rki.de/" target="_blank">• Covid-19 Fallzahlen</a>
        </mat-list-item>

        <mat-list-item>
          <div mat-line>
            <a href="http://risklayer.com/">Risklayer GmbH</a> and 
          <a href="https://www.cedim.kit.edu/" matTooltip="Center for Disaster Management and Risk Reduction Technology">CEDIM</a> at 
          <a href="https://www.kit.edu/" matTooltip="Karlsruhe Institute of Technology">KIT</a>
          </div>
          <div mat-line>
            and the <a href="https://docs.google.com/spreadsheets/d/1wg-s4_Lz2Stil6spQEYFdZaBEp8nWW26gVyfHqvcl8s/htmlview#">Risklayer-CEDIM-Tagesspiegel SARS-CoV-2 -</a>
          </div>
          <div mat-line>
            <a href="https://docs.google.com/spreadsheets/d/1wg-s4_Lz2Stil6spQEYFdZaBEp8nWW26gVyfHqvcl8s/htmlview#">Crowdsourcing Contributors</a>
          </div>
        </mat-list-item>

        <mat-list-item role="listitem">
          <a i18n="@@infoboxDESTATIS" href="https://www.destatis.de/" target="_blank">Statistisches Bundesamt</a>
        </mat-list-item>

        <mat-list-item role="listitem">
          <a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>
        </mat-list-item>

      </mat-list>


    </mat-expansion-panel>
  </mat-accordion>
</div>

  <app-button-panel class="footer" [mapOptions]="mo" [mapLocationSettings]="mls"></app-button-panel>


</mat-card>
